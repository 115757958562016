import React from "react";
import theme from "theme";
import { Theme, Text, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"politika"} />
		<Helmet>
			<title>
				PlayFooty
			</title>
			<link rel={"shortcut icon"} href={"https://inkzstar-place.com/img/8133849.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<meta name={"msapplication-TileImage"} content={"https://inkzstar-place.com/img/8133849.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" text-align="left" />
			<Text as="h1" font="--headline2" margin="20px 0 0 0">
				Gizlilik Politikası
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Bu Gizlilik Politikası ("Politika"), inkzstar-place.com ("biz", "bizi" veya "bizim") olarak, ziyaretçilerin ve kullanıcıların ("siz" veya "sizin") inkzstar-place.com web sitesi ve ilişkili hizmetleri ("Hizmetler") kapsamında kişisel verilerinizi nasıl topladığımızı, kullandığımızı, aktardığımızı ve koruduğumuzu açıklar.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Hizmetlerimizi kullanarak, bu Politikada açıklanan uygulamaları kabul etmiş olursunuz. Bu Politikayı kabul etmiyorsanız, lütfen Hizmetlerimizi kullanmayın.
			</Text>
			<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
				1. Topladığımız Bilgiler
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Aşağıdaki türde bilgileri toplayabiliriz:
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				1.1 Kişisel Veriler: Hizmetlerimizle etkileşiminiz sırasında adınız, e-posta adresiniz, posta adresiniz gibi kişisel verileri ve gönüllü olarak sağladığınız diğer bilgileri toplayabiliriz.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				1.2 Kullanım Bilgileri: Hizmetlerimizle etkileşiminiz hakkında, IP adresiniz, tarayıcı türünüz, işletim sistemi ve ziyaret edilen sayfalar gibi kişisel olmayan bilgileri toplayabiliriz. Ayrıca bu bilgileri toplamak için çerezler ve benzeri teknolojiler kullanabiliriz.
			</Text>
			<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
				2. Bilgilerinizi Nasıl Kullanıyoruz
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Verilerinizi aşağıdaki amaçlar doğrultusunda kullanıyoruz:
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				2.1 Hizmetlerin Sağlanması ve İyileştirilmesi: Verilerinizi, Hizmetlerimizin sağlanması, bakımının yapılması ve iyileştirilmesi için kullanıyoruz, bu kapsamda taleplerinize ve isteklerinize yanıt verilmesi de dahildir.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				2.2 Analiz: Kullanıcı eğilimlerini ve tercihlerini analiz etmek için analiz araçlarını kullanıyoruz, bu da Hizmetlerimizi iyileştirmemize yardımcı olur.
			</Text>
			<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
				3. Bilgilerinizin Paylaşımı
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Kişisel bilgilerinizi üçüncü şahıslara satmıyor, kiralamıyor veya takas etmiyoruz. Ancak, aşağıdaki durumlarda bilgilerinizi paylaşabiliriz:
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				3.1 Yasal Gereklilikler: Yasaların, düzenlemelerin, yasal süreçlerin veya hükümet taleplerinin gerektirdiği durumlarda bilgilerinizi açıklayabiliriz.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				3.2 Hakların Korunması: Haklarımızı, gizliliğimizi, güvenliğimizi veya mülkiyetimizi korumak veya herhangi bir kişinin güvenliğini tehdit eden bir acil duruma yanıt vermek gerektiğinde bilgilerinizi açıklayabiliriz.
			</Text>
			<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
				4. Seçenekleriniz
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Kişisel bilgilerinizle ilgili olarak aşağıdaki seçeneklere sahipsiniz:
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				4.1 Erişim ve Düzeltme: Kişisel bilgilerinize erişebilir ve bunları düzeltebilirsiniz, bu konuda bizimle contact@inkzstar-place.com adresinden iletişime geçebilirsiniz.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				4.2 Reddetme: Bizden reklam e-postaları almayı reddedebilirsiniz, bu e-postalardaki talimatları takip ederek.
			</Text>
			<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
				5. Güvenlik
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Bilgilerinizi korumak için makul idari, teknik ve fiziksel önlemler kullanıyoruz. Ancak, internet üzerinden yapılan hiçbir veri iletimi veya elektronik depolama yöntemi tamamen güvenli değildir ve mutlak güvenliği garanti edemeyiz.
			</Text>
			<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
				6. Çocukların Gizliliği
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Hizmetlerimiz 13 yaşından küçükler için tasarlanmamıştır. 13 yaşından küçük bir çocuğun bilgilerini yanlışlıkla topladığımızı düşünüyorsanız, bizimle iletişime geçin ve bu bilgileri hemen sileceğiz.
			</Text>
			<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
				7. Gizlilik Politikasındaki Değişiklikler
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Gizlilik Politikamızı zaman zaman güncelleyebiliriz. Veri işleme yöntemlerimiz hakkında bilgi sahibi olmak için lütfen bu Politikayı düzenli olarak gözden geçirin.
			</Text>
			<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
				8. Bize Ulaşın
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Bu Gizlilik Politikası veya veri işleme yöntemlerimizle ilgili herhangi bir sorunuz, endişeniz veya talepleriniz varsa, bizimle contact@inkzstar-place.com üzerinden iletişime geçin.
			</Text>
		</Section>
		<Components.Footer />
	</Theme>;
});